/*---------------------------------------------- RESET */

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}p,ul,ol{margin:0 0 20px}ul,ol{padding:0 0 0 2.5em}label{cursor:pointer}button{cursor:pointer;border:0;outline:0}input[type="submit"]::-moz-focus-inner,input[type="button"]::-moz-focus-inner{border:0}input[type="submit"]:focus,input[type="button"]:focus{outline:0}


/*---------------------------------------------- GLOBAL SETTINGS */


html, body{ height: 100%; }

body {
	background: #fff;
	font: 14px/21px "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #444;
	-webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
	-webkit-text-size-adjust: 100%;
}

/*---------------------------------------------------- FORMATTING */

h1, h2, h3, h4, h5, h6 { font-weight: normal; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }
h1 { font-size: 46px; line-height: 50px; margin-bottom: 14px;}
h2 { font-size: 35px; line-height: 40px; margin-bottom: 10px; }
h3 { font-size: 28px; line-height: 34px; margin-bottom: 8px; }
h4 { font-size: 21px; line-height: 30px; margin-bottom: 4px; }
h5 { font-size: 17px; line-height: 24px; }
h6 { font-size: 14px; line-height: 21px; }

p { margin: 0 0 20px 0; }
em { font-style: italic; }
strong { font-weight: bold; }
small { font-size: 80%; }

/*	Blockquotes  */
blockquote, blockquote p { font-size: 17px; line-height: 24px; color: #777; font-style: italic; }
blockquote { margin: 0 0 20px; padding: 9px 20px 0 19px; border-left: 1px solid #ddd; }
blockquote cite { display: block; font-size: 12px; color: #555; }
blockquote cite:before { content: "\2014 \0020"; }
blockquote cite a, blockquote cite a:visited, blockquote cite a:visited { color: #555; }

hr { border: solid #ddd; border-width: 1px 0 0; clear: both; margin: 10px 0 30px; height: 0; }

a { color: #333; text-decoration: underline; outline: 0; }
p a { line-height: inherit; }

input, textarea {
	font-size: 1em;
	font-family: Helvetica, Arial, sans-serif;
	-webkit-transition: border-color .2s ease-out,-webkit-box-shadow .2s ease-out;
	-moz-transition: border-color .2s ease-out,-moz-box-shadow .2s ease-out; 
	background:  #fff;
	border: 1px solid #ddd;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	color: #666;
}

ul, ol { margin-bottom: 20px; }
ul { list-style: none outside; }
ul ul, ul ol,
ol ol, ol ul { margin: 4px 0 5px 30px; font-size: 90%;  }
ul ul li, ul ol li,
ol ol li, ol ul li { margin-bottom: 6px; }
li { line-height: 18px; margin-bottom: 12px; }

/*------------------------------------------------------ LAYOUT - HIDE UNNECESSARY ELEMENTS HERE */

nav {display: none; }
